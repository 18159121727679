.ct-series-a .ct-slice-pie {
    fill: #EFEEC4;
}
.ct-series-b .ct-slice-pie {
    fill: #C2D33B;
}
.ct-series-c .ct-slice-pie {
    fill: #8C8B20;
}
.ct-series-d .ct-slice-pie {
    fill: #F6A222;
}


.ct-label{
    font-size: 0.8rem;
    font-weight: bold;
}